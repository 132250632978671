<template>
	<div>
		<tableList ref="TableList" @buttonMethods="buttonMethods" @itemButtonMethods="itemButtonMethods" :sUrl="sUrl"></tableList>
		<tableDrawer ref="tableDrawer" :key="nTableDrawerKey" ></tableDrawer>
	</div>
</template>

<script>
	import tableList from '@/components/TableList.vue';
	import Vue from 'vue';

	export default {
		components: {
			tableList,
		},
		data() {
			return {
				sUrl : this.$route.path, // 当前页面路径
				oOutJs : '', // 引用的第三方文件
				nTableDrawerKey: 0, // 组件的key值 更新该值会丢弃上一次渲染的资源
			};
		},
		async created() {
			const path = this.$route.path;
			try {
				Vue.component('tableDrawer', function (resolve) {
					require(['@/views' + path +'/tableDrawer.vue'], resolve)
				});
			} catch (e) {
				console.log('组件不存在，请手动创建一个');
			}
			
			
			try {
				this.oOutJs = await import ("@/js" + path + ".js");
			} catch (e) {
				console.log('js文件不存在，请手动创建一个');
			}
		},
		methods: {
			/**
			 * 顶部按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			buttonMethods(action, param) {
				console.log('触发方法'+action)
				var that = this
				this.oOutJs[action](param, that)
			},
			/**
			 * 行内按钮点击事件
			 * @param {Object} action 触发方法
			 * @param {Object} param 携带参数
			 */
			itemButtonMethods(action, param){
				var that = this
				this.oOutJs[action](param, that)
			},
		},
	};
</script>

<style>
</style>
